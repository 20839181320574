<template>
    <ts-loading-banner :loading="loading">
        <div class="tw-space-y-3">
            <div class="row">
                <div class="col-md-12 tw-space-y-2">
                    <label class="required">{{
                        $t("bookingPickup.driverName")
                    }}</label>
                    <a-select
                        show-search
                        v-model="model.driver_id"
                        style="width: 100%"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        :placeholder="$t('bookingPickup.typeToSearch')"
                        @search="handleDriverSearch"
                        @change="getBooking"
                    >
                        <a-select-option v-for="d in driverList" :key="d.driver_id">
                            {{ d.driver_name }}
                        </a-select-option>
                    </a-select>
                    <div class="tw-text-red-500" v-if="errors.has('driver_id')">
                        {{ errors.first("driver_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-2">
                    <label class="required">{{
                        $t("bookingPickup.shopName")
                    }}</label>
                    <a-select
                        show-search
                        v-model="model.shop_id"
                        style="width: 100%"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        :placeholder="$t('bookingPickup.typeToSearch')"
												@search="handleShopSearch"
												@change="getBooking"
                    >
                        <a-select-option v-for="s in shopList" :key="s.shop_id">
                            {{ s.shop_name }}
                        </a-select-option>
                    </a-select>
                    <div class="tw-text-red-500" v-if="errors.has('shop_id')">
                        {{ errors.first("shop_id") }}
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <h5>{{ $t("bookingPickup.packages") }}</h5>
                    <div
                        class="tw-border tw-rounded tw-p-2 tw-overflow-y-scroll"
                        style="max-height: 500px"
                    >
                        <table class="tw-w-full">
                            <thead>
                                <tr>
                                    <th
                                        class="tw-uppercase tw-whitespace-nowrap tw-py-1 tw-px-2 tw-bg-blue-500 tw-text-white tw-border tw-w-5"
                                    >
                                        <ts-checkbox
                                            :checkedValue="checkAll"
                                            @change="onCheckAll"
                                        ></ts-checkbox>
                                    </th>
                                    <th
                                        class="tw-uppercase tw-whitespace-nowrap tw-py-1 tw-px-2 tw-bg-blue-500 tw-text-white tw-border tw-w-5"
                                    >
                                        {{ $t("bookingPickup.no") }}
                                    </th>
                                    <th
                                        class="tw-uppercase tw-whitespace-nowrap tw-py-1 tw-px-2 tw-bg-blue-500 tw-text-white tw-border"
                                    >
                                        {{ $t("bookingPickup.bookingTime") }}
                                    </th>
                                    <th
                                        class="tw-uppercase tw-whitespace-nowrap tw-py-1 tw-px-2 tw-bg-blue-500 tw-text-white tw-border"
                                    >
                                        {{ $t("bookingPickup.bookingNumber") }}
                                    </th>
                                    <th
                                        class="tw-uppercase tw-whitespace-nowrap tw-py-1 tw-px-2 tw-bg-blue-500 tw-text-white tw-border"
                                    >
                                        {{ $t("bookingPickup.voucherNumber") }}
                                    </th>
                                    <th
                                        class="tw-uppercase tw-whitespace-nowrap tw-py-1 tw-px-2 tw-bg-blue-500 tw-text-white tw-border"
                                    >
                                        {{ $t("bookingPickup.shopCode") }}
                                    </th>
                                    <th
                                        class="tw-uppercase tw-whitespace-nowrap tw-py-1 tw-px-2 tw-bg-blue-500 tw-text-white tw-border"
                                    >
                                        {{ $t("bookingPickup.shopName") }}
                                    </th>
                                    <th
                                        class="tw-uppercase tw-whitespace-nowrap tw-py-1 tw-px-2 tw-bg-blue-500 tw-text-white tw-border"
                                    >
                                        {{ $t("bookingPickup.bookingNumber") }}
                                    </th>
                                    <th
                                        class="tw-uppercase tw-whitespace-nowrap tw-py-1 tw-px-2 tw-bg-blue-500 tw-text-white tw-border"
                                    >
                                        {{ $t("bookingPickup.packageType") }}
                                    </th>
                                    <th
                                        class="tw-uppercase tw-whitespace-nowrap tw-py-1 tw-px-2 tw-bg-blue-500 tw-text-white tw-border"
                                    >
                                        {{ $t("bookingPickup.receiverPhone") }}
                                    </th>
                                    <th
                                        class="tw-uppercase tw-whitespace-nowrap tw-py-1 tw-px-2 tw-bg-blue-500 tw-text-white tw-border tw-w-9 tw-whitespace-nowrap"
                                    >
                                        {{
                                            $t("bookingPickup.numberOfPackage")
                                        }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(b, index) in booking" :key="index">
                                    <td
                                        class="tw-py-1 tw-px-2 tw-border tw-text-center"
                                    >
                                        <ts-checkbox
                                            v-model="model.booking_id"
                                            :value="b.booking_id"
                                        ></ts-checkbox>
                                    </td>
                                    <td
                                        class="tw-py-1 tw-px-2 tw-border tw-text-center"
                                    >
                                        {{ index + 1 }}
                                    </td>
                                    <td
                                        class="tw-py-1 tw-px-2 tw-border tw-text-left"
                                    >
                                        {{ b.booking_time }}
                                    </td>
                                    <td
                                        class="tw-py-1 tw-px-2 tw-border tw-text-left"
                                    >
                                        {{ b.booking_number }}
                                    </td>
                                    <td
                                        class="tw-py-1 tw-px-2 tw-border tw-text-left"
                                    >
                                        {{ b.voucher_no }}
                                    </td>
                                    <td
                                        class="tw-py-1 tw-px-2 tw-border tw-text-left"
                                    >
                                        {{ b.shop_code }}
                                    </td>
                                    <td
                                        class="tw-py-1 tw-px-2 tw-border tw-text-left"
                                    >
                                        {{ b.shop_name }}
                                    </td>
                                    <td
                                        class="tw-py-1 tw-px-2 tw-border tw-text-left"
                                    >
                                        {{ b.booking_number }}
                                    </td>
                                    <td
                                        class="tw-py-1 tw-px-2 tw-border tw-text-left"
                                    >
                                        {{ b.package_type_kh }}
                                    </td>
                                    <td
                                        class="tw-py-1 tw-px-2 tw-border tw-text-left"
                                    >
                                        {{ b.receiver_phone }}
                                    </td>
                                    <td
                                        class="tw-py-1 tw-px-2 tw-border tw-text-center"
                                    >
                                        {{ b.number_of_package }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div
                        class="tw-text-red-500"
                        v-if="errors.has('booking_id')"
                    >
                        {{ errors.first("booking_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                    <ts-button
                        @click.prevent="$emit('cancel')"
                        :disabled="loading"
                        >{{ $t("cancel") }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        :disabled="loading"
                        @click.prevent="onConfirm"
                        >{{ $t("confirm") }}</ts-button
                    >
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash";

export default {
    name: "checkedPackage",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            shop: [],
            driver: [],
            booking: [],
            model: {
                booking_id: [],
                driver_id: undefined,
                shop_id: undefined
            }
        };
    },
    created() {
        // this.getBooking();
    },
    computed: {
        ...mapState("driver/bookingPickup", ["formModels"]),
        checkAll() {
            if (this.model.booking_id.length == 0) return false;
            return this.booking.length == this.model.booking_id.length;
        },
        shopList() {
            if (!this.formModels.shop) return [];
            return this.formModels.shop;
        },
        bookingList() {
            if (!this.formModels.bookingFromShop) return [];
            return this.formModels.bookingFromShop;
        },
        driverList() {
            if (!this.formModels.driver) return [];
            return this.formModels.driver;
        }
    },
    methods: {
        ...mapActions("driver/bookingPickup", [
            "getFormViewData",
            "assingDriver"
        ]),
        fetchFormView: debounce(function({ params }) {
            this.loading = true;
            this.getFormViewData({ params: params, index: undefined })
                .then(response => {
                    this[params.fnName] = response.data[params.fnName];
                })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        }, 500),
        handleDriverSearch: debounce(function(value) {
            this.fetchFormView({
                params: {
                    fnName: "driver",
                    driver_search: value
                }
            });
        }, 500),
        handleShopSearch: debounce(function(value) {
            this.fetchFormView({
                params: {
                    fnName: "shop",
                    shop_search: value
                }
            });
        }, 500),
        getBooking: debounce(function() {
            this.loading = true;
            this.fetchFormView({
                params: {
                    fnName: "booking",
										driver_id: this.model.driver_id,
										shop_id: this.model.shop_id,
                }
            });
        }, 100),
        onCheckAll(e) {
            if (e) {
                this.model.booking_id = [];
                this.booking.forEach(book => {
                    this.model.booking_id.push(book.booking_id);
                });
            } else {
                this.model.booking_id = [];
            }
        },
        onConfirm() {
            this.errors = new Errors();
            this.loading = true;
            this.assingDriver(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
