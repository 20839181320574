<template>
    <div>
        <ts-page-title
            :title="$t('bookingPickup.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('bookingPickup.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <div class="tw-space-x-3">
                        <a-button
                            v-if="$can('create-booking-pickups')"
                            type="primary"
                            @click="addNew"
                        >
                            {{ $t("addNew") }}
                        </a-button>
                        <a-button
                            v-if="$can('create-check-packages')"
                            type="dashed"
                            @click="() => (show_form_checked = true)"
                        >
                            {{ $t("bookingPickup.checkedPackage") }}
                        </a-button>
                        <a-range-picker
                            v-model="dateRange"
                            :allowClear="true"
                            format="DD-MM-YYYY"
                            valueFormat="DD-MM-YYYY"
                            style="width: 200px"
                            @change="fetchData()"
                        />
                        <a-button
                            v-if="$can('create-confirm-booking')"
                            type="primary"
                            @click="() => (show_form_confirm_from_shop = true)"
                        >
                            {{ $t("confirm") }}
                        </a-button>
                        <a-select
                            v-model="status"
                            style="width: 220px"
                            :options="statusOpt"
                            showSearch
                            :filterOption="
                                (input, option) =>
                                    option.componentOptions.children[0].text
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                            "
                            @change="fetchData()"
                        >
                        </a-select>
                    </div>
                    <div class="tw-space-x-3 tw-flex tw-items-center">
                        <ts-checkbox
                            v-model="isStockOrder"
                            @change="fetchData()"
                        >
                            {{ $t("bookingPickup.isFromPackaging") }}
                        </ts-checkbox>
                        <a-radio-group
                            v-model="isDelivery"
                            @change="fetchData()"
                        >
                            <a-radio-button :value="0">
                                {{ $t("bookingPickup.notYetDelivery") }}
                            </a-radio-button>
                            <a-radio-button :value="1">
                                {{ $t("bookingPickup.delivered") }}
                            </a-radio-button>
                        </a-radio-group>
                        <a-tooltip :title="$t('bookingPickup.search')">
                            <a-input-search
                                v-model="search"
                                :placeholder="$t('bookingPickup.search')"
                                style="width: 200px"
                            />
                        </a-tooltip>
                    </div>
                </div>
            </ts-panel-wrapper>
            <div class="tw-overflow-auto">
                <ts-table
                    :columns="columns"
                    :records="resources"
                    :loading="loading"
                >
                    <template v-slot="{ record }">
                        <!-- <td class="tw-text-center">
                            <ActionBtn
                                v-model="model.booking_id"
                                :record="record"
                                :value="record.booking_id"
                            />
                        </td> -->
                        <td class="tw-whitespace-nowrap">
                            {{ record.booking_time }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.booking_number }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.voucher_no }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.shop_name }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.driver_name }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.vehicle_types }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.receiver_phone }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.package_type_kh }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.service_type_name_kh }}
                        </td>
                        <td class="tw-whitespace-nowrap tw-text-center">
                            {{ record.number_of_package }}
                        </td>
                        <td class="tw-whitespace-nowrap tw-text-center">
                            {{ record.num_package_pickup }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            <ts-accounting-format
                                :value="record.purchase_amount"
                                :digit="2"
                            />
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.zone_name }}
                        </td>
                        <td class="tw-whitespace-nowrap">
                            {{ record.destination }}
                        </td>
                        <td class="tw-text-left tw-whitespace-nowrap">
                            {{ record.delivery_status }}
                        </td>
                        <td class="tw-text-left">
                            {{ record.user_name }}
                        </td>
                        <td class="tw-text-left">
                            {{ record.checked_by_name }}
                        </td>
                        <td class="tw-text-left tw-whitespace-nowrap">
                            {{ record.checked_date }}
                        </td>
                        <td class="tw-text-left">
                            {{ record.confirm_by_name }}
                        </td>
                        <td class="tw-text-left tw-whitespace-nowrap">
                            {{ record.confirm_date }}
                        </td>
                        <td class="tw-text-left tw-whitespace-nowrap">
                            {{ record.packaging_number }}
                        </td>
                        <td class="text-left tw-space-x-3 tw-whitespace-nowrap">
                            <a
                                v-if="$can('view-booking-pickups')"
                                href="#"
                                class="text-primary mr-2"
                                @click.prevent="showHistoryForm(record)"
                            >
                                <i class="fas fa-history"></i>
                            </a>
                            <a
                                v-if="$can('update-booking-pickups')"
                                href="#"
                                v-tooltip="$t('edit')"
                                class="text-primary mr-2"
                                @click.prevent="edit(record)"
                            >
                                <i class="fas fa-edit"></i>
                            </a>
                            <a-popconfirm
                                v-if="$can('delete-booking-pickups')"
                                placement="leftTop"
                                :title="$t('are_you_sure_to_delete')"
                                :ok-text="$t('yes')"
                                :cancel-text="$t('no')"
                                @confirm="deleteRecord(record)"
                            >
                                <a href="#" :disabled="record._deleting">
                                    <i
                                        class="fas fa-circle-notch spin"
                                        v-if="record._deleting"
                                    ></i>
                                    <i class="fa fa-trash text-red" v-else></i>
                                </a>
                            </a-popconfirm>
                        </td>
                    </template>
                </ts-table>
            </div>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>

        <!-- form-action -->
        <a-modal
            v-if="showForm"
            v-model="showForm"
            :title="$t('bookingPickup.pageTitle')"
            centered
            :maskClosable="false"
            @cancel="closeForm"
            :zIndex="1020"
            :footer="null"
            width="550px"
        >
            <from-action @cancel="closeForm" />
        </a-modal>

        <!-- form checked package -->
        <a-modal
            v-if="show_form_checked"
            v-model="show_form_checked"
            :title="$t('bookingPickup.checkedPackage')"
            centered
            :maskClosable="false"
            :zIndex="1020"
            :footer="null"
            width="70%"
            @cancel="onCheckPackageFormClose"
        >
            <CheckPackage @cancel="onCheckPackageFormClose" />
        </a-modal>

        <!-- show history -->
        <a-modal
            v-if="showHistory"
            v-model="showHistory"
            :title="$t('bookingPickup.history')"
            centered
            :maskClosable="false"
            :zIndex="1020"
            :footer="null"
            width="30%"
        >
            <History :id="booking_id" />
        </a-modal>

        <!-- show confirm from shop -->
        <a-modal
            v-if="show_form_confirm_from_shop"
            v-model="show_form_confirm_from_shop"
            :title="$t('bookingPickup.confirmFromShop')"
            centered
            :maskClosable="false"
            :zIndex="1020"
            :footer="null"
            width="70%"
        >
            <ConfirmFromShop @cancel="onConfirmFromShopClose" />
        </a-modal>
    </div>
</template>

<script>
import { debounce } from "lodash";
import { mapState } from "vuex";
import FromAction from "./form";
import CheckPackage from "./form-checked-package.vue";
import ConfirmFromShop from "./form-confime-from-shop.vue";
import History from "./components/history.vue";
import { Errors } from "form-backend-validation";
import moment from "moment";

export default {
    name: "bookingIndex",
    components: {
        FromAction,
        CheckPackage,
        History,
        ConfirmFromShop
    },
    data() {
        return {
            isPackaging: false,
            showHistory: false,
            showForm: false,
            loading: false,
            show_update_form: false,
            show_form_checked: false,
            show_form_confirm_from_shop: false,
            booking_id: undefined,
            errors: new Errors()
        };
    },
    computed: {
        ...mapState("driver/bookingPickup", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.driver.bookingPickup.search;
            },
            set(newValue) {
                this.$store.commit("driver/bookingPickup/SET_SEARCH", newValue);
                this.$store.commit("driver/bookingPickup/RESET_CURRENT_PAGE");
            }
        },
        isDelivery: {
            get() {
                return this.$store.state.driver.bookingPickup.isDelivery;
            },
            set(newValue) {
                this.$store.commit(
                    "driver/bookingPickup/SET_IS_DELIVERY",
                    newValue
                );
            }
        },
        isStockOrder: {
            get() {
                return this.$store.state.driver.bookingPickup.is_stock_order;
            },
            set(newValue) {
                this.$store.commit(
                    "driver/bookingPickup/SET_STOCK_ORDER",
                    newValue
                );
            }
        },
        columns() {
            return [
                {
                    name: this.$t("bookingPickup.bookingTime"),
                    style: "width:30px", sortKey: 'booking_time'
                },
                { name: this.$t("bookingPickup.bookingNumber"), sortKey: 'booking_number' },
                { name: this.$t("bookingPickup.voucherNumber") , sortKey: 'voucher_no'},
                { name: this.$t("bookingPickup.shopName"), sortKey: 'shop_name' },
                { name: this.$t("bookingPickup.driverName"), sortKey: 'driver_name' },
                { name: this.$t("bookingPickup.vehicleType"), sortKey: 'vehicle_types' },
                { name: this.$t("bookingPickup.receiverPhone"), sortKey: 'receiver_phone' },
                { name: this.$t("bookingPickup.packageType"), sortKey: 'package_type_kh' },
                { name: this.$t("bookingPickup.serviceType"), sortKey: 'service_type_name_kh' },
                { name: this.$t("bookingPickup.numberOfPackage"), sortKey: 'number_of_package' },
                {
                    name: this.$t("bookingPickup.numberPickupPackage"), sortKey: 'num_package_pickup'
                },
                { name: this.$t("bookingPickup.purchaseAmount"), sortKey: 'purchase_amount' },
                { name: this.$t("bookingPickup.zone"), sortKey: 'zone_name' },
                { name: this.$t("bookingPickup.destination"), sortKey: 'destination' },
                {
                    name: this.$t("bookingPickup.status"),
                    style: "text-align:center", sortKey: 'delivery_status'
                },
                {
                    name: this.$t("bookingPickup.createdBy"), sortKey: 'user_name'
                },
                {
                    name: this.$t("bookingPickup.checkedBy"), sortKey: 'checked_by_name'
                },
                {
                    name: this.$t("bookingPickup.checkedDate"), sortKey: 'checked_date'
                },
                {
                    name: this.$t("bookingPickup.confirmBy"), sortKey: 'confirm_by_name'
                },
                {
                    name: this.$t("bookingPickup.confirmDate"), sortKey: 'confirm_date'
                },
                {
                    name: this.$t("bookingPickup.packageNumber"), sortKey: 'packaging_number'
                },
                {
                    name: this.$t("actions"),
                    style: "width:100px"
                }
            ];
        },
        dateRange: {
            get() {
                return this.$store.state.driver.bookingPickup.dateRange;
            },
            set(newValue) {
                this.$store.commit(
                    "driver/bookingPickup/SET_DATE_RANGE",
                    newValue
                );
            }
        },
        status: {
            get() {
                return this.$store.state.driver.bookingPickup.status;
            },
            set(newValue) {
                this.$store.commit("driver/bookingPickup/SET_STATUS", newValue);
            }
        },
        statusOpt() {
            return [
                { value: "", label: "All" },
                { value: 0, label: "Booking" },
                { value: 1, label: "Request For Delivery" },
                { value: 2, label: "On Delivery" },
                { value: 3, label: "Re-Delivery" },
                { value: 4, label: "Dropped" },
                { value: 5, label: "Transferred" },
                { value: 6, label: "Success" },
                { value: 7, label: "Cancelled" },
                { value: 8, label: "Pending" },
                { value: 9, label: "Return" },
                { value: 10, label: "Confirm Delivery" },
                { value: 11, label: "Confirm Booking" }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("driver/bookingPickup/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        addNew() {
            this.$router.push({ name: "booking-pickup-create" });
            // this.showForm = true;
        },
        closeForm() {
            this.$store.commit("driver/bookingPickup/SET_EDIT_DATA", {});
            this.fetchData();
            this.showForm = false;
        },
        edit(record) {
            this.$store.commit("driver/bookingPickup/SET_EDIT_DATA", record);
            this.showForm = true;
        },
        deleteRecord(record) {
            record._deleting = true;
            this.$store
                .dispatch("driver/bookingPickup/destroy", record.booking_id)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.fetchData();
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    record._deleting = false;
                });
        },
        onCheckPackageFormClose() {
            this.show_form_checked = false;
            this.fetchData();
        },
        onConfirmFromShopClose() {
            this.show_form_confirm_from_shop = false;
            this.fetchData();
        },
        showHistoryForm(record) {
            this.showHistory = true;
            this.booking_id = record.booking_id;
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.commit("driver/bookingPickup/SET_DATE_RANGE", [
                moment().format("DD-MM-YYYY"),
                moment().format("DD-MM-YYYY")
            ]);
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("driver/bookingPickup/RESET_STATE");
        next();
    }
};
</script>
