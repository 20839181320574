<template>
	<div>
		<h1 class="tw-mb-0 tw-text-base">Booking Nunber: {{ items[0] ? items[0].booking_number: '-' }}</h1>
		<h3 class="tw-mb-4"><small><i>Receiver Phone: {{ items[0] ? items[0].receiver_phone: '-' }}</i></small></h3>
		<h1 class="tw-mb-4"><small><i>First Driver: {{ items[0] ? items[0].driver_name: '-' }}</i></small></h1>
		<ts-loading-banner :loading="loading">
			<ol class="tw-relative tw-border-l tw-border-gray-200 tw-dark:border-gray-700">
				<li class="tw-mb-10 tw-ml-6" v-for="(i, index) in items" :key="index">
					<span
						class="tw-flex tw-absolute tw--left-3 tw-justify-center tw-items-center tw-w-6 tw-h-6 tw-bg-blue-200 tw-rounded-full tw-ring-8 tw-ring-white tw-dark:ring-gray-900 tw-dark:bg-blue-900">
						<svg class="tw-w-3 tw-h-3 tw-text-blue-600 dark:tw-text-blue-400" fill="currentColor"
							viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd"
								d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
								clip-rule="evenodd"></path>
						</svg>
					</span>
					<h3
						class="tw-flex tw-items-center tw-mb-1 tw-text-lg tw-font-semibold tw-text-gray-900 tw-dark:text-white">
						{{ i.status_time }}</h3>
					<div
						class="tw-p-3 tw-text-xs tw-italic tw-font-normal tw-text-gray-500 tw-bg-gray-50 tw-rounded-lg tw-border tw-border-gray-200 tw-dark:bg-gray-600 tw-dark:border-gray-500 tw-dark:text-gray-300">
						<label>Action By -> </label>
						<label class="tw-text-blue-400">{{ i.created_by }}</label><br>
						<label>{{ i.status_remark }}</label>
						<!-- <label class="tw-text-blue-400">{{ i.driver_name }}</label> -->
						
					</div>
				</li>
			</ol>
		</ts-loading-banner>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
	name: 'bookingHistory',
	props: ['id'],
	data() {
		return {
			loading: false,
			items: []
		}
	},
	created() {
		this.showData();
	},
	methods: {
		...mapActions("driver/bookingPickup", ["history"]),
		showData() {
			this.loading = true
			this.history(this.id)
				.then(response => {
					this.items = response.data
				})
				.catch(error => {
					this.$notify({ type: "error", text: error.message });
				})
				.finally(() => this.loading = false)
		}
	},
};
</script>
